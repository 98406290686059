// This file can replace src/environments/environments.ts during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { ApplicationVersion, PlatformType } from 'src/app/shared/enums';

const firebaseConfig = {
  databaseURL: 'https://inforce911-ea334.firebaseio.com',
  apiKey: "AIzaSyDY0CXY6fkD712dgxZzD8JtykLZqFIqvv4",
  authDomain: "inforcetest-eba87.firebaseapp.com",
  projectId: "inforcetest-eba87",
  storageBucket: "inforcetest-eba87.appspot.com",
  messagingSenderId: "519206562217",
  appId: "1:519206562217:web:04a34b6e3c7f59f09cbcb8"
};

export const environment = {
  production: true,
  baseURL: 'https://portalstaging.inforce911.com/rest',
  webAppBaseUrl: '/INFORCE911AlertingApp/',
  applicationVersion: ApplicationVersion.Agency,
  platformType: PlatformType.Browser,
  contactNumber: '911',
  firebaseConfig
};
