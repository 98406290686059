import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Router } from '@angular/router';

import { LoginService } from '../services/login.service';
import { UserService } from '../services//user.service';
import { LoadingService } from '../services/loading.service';
import { NavController } from '@ionic/angular';
import { EventService } from '../services/events.service';

export const authGuard = () => {
  const userService = inject(UserService);
  const loadingService = inject(LoadingService);
  const loginService = inject(LoginService);
  const router = inject(Router);

  return Promise.all([userService.getUser(), userService.getAuthToken()])
    .then((values: any[]) => {
      if (values[0] && values[1]) {
        // We have a user and a valid auth token in storage
        return loginService.validate().then(() => {
          loadingService.dismiss();
          return true;
        });
      }
      // We are missing a user or an auth token in storage
      loadingService.dismiss();
      router.navigate(['/login']);
      return false;
    })
    .catch((err: any) => {
      // Our auth token is invalid
      loadingService.dismiss();
      router.navigate(['/login']);
      return false;
    });
};
