import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Device } from '@capacitor/device';
import { v4 as uuid } from 'uuid';

import { environment } from '../../../environments/environment';
import { BaseUser, User } from '../models/user';
import { LoginCredentials } from '../models/loginCredentials';
import { UserService } from './user.service';
import { DeviceData } from '../models/deviceData';
import { LoadingService } from './loading.service';
import { ApplicationService } from './application.service';
import { Subject, lastValueFrom } from 'rxjs';
import { UIAlertService } from './uiAlert.service';
import { SubscriptionService } from './subscription.service';
import { ChatService } from './chat.service';
import { DeviceType } from '../enums';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  showingDialog = false;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private loadingService: LoadingService,
    private applicationService: ApplicationService,
    private uiAlertService: UIAlertService,
    private subscriptionService: SubscriptionService,
    private chatService: ChatService,
    private notificationsService: NotificationsService
  ) {}

  attemptLogin(user: User, formIsValid: boolean): Promise<string> {
    if (!formIsValid) {
      return;
    }

    return new Promise(async (resolve, reject) => {
      // Check if the EULA has been accepted on this device
      const alreadyAcceptedEULA = await this.userService.getAcceptedEULA();
      if (!alreadyAcceptedEULA) {
        const acceptedEULA = await this.uiAlertService.presentEULA();
        if (!acceptedEULA) {
          reject('EULA must be accepted to proceed.');
          return;
        }
      }

      this.loadingService.present();

      // Check if this device has been already registered
      this.userService.getDeviceData().then(async (data: DeviceData) => {
        const deviceCode = data.deviceCode;
        const deviceKey = data.deviceKey;
        let deviceIdentifier = data.deviceIdentifier;

        // Register device if it wasn't registered yet
        if (deviceCode === null || deviceKey === null) {
          if (deviceIdentifier === null) {
            deviceIdentifier = uuid();
            this.userService.setDeviceIdentifier(deviceIdentifier);
          }

          let deviceInfo = await Device.getInfo();

          const requestBody = {
            deviceIdentifier,
            deviceType: this.applicationService.getDeviceType(),
            deviceName: deviceInfo.name || 'Default Device Name'
          };

          this.http.post(`${environment.baseURL}/accounts/register`, requestBody).subscribe({
            next: (response: any) => {
              // Failed registration
              if (response.DeviceCode === null || response.Device === null) {
                reject('Failed to register device.');
              } else {
                // Successful registration
                this.userService.setDeviceCode(response.DeviceCode);
                this.userService.setDeviceKey(response.DeviceKey);

                const { DeviceCode, DeviceKey } = response;
                const { password } = user;
                const username = user.username.trim();
                const uniqueId = user.uniqueId.trim();

                this.authenticate({
                  credentials: {
                    username,
                    password,
                    deviceCode: DeviceCode, // These fields coming from the API are pascal cased
                    deviceKey: DeviceKey
                  },
                  appType: this.applicationService.isOrgUser ? 2 : 1, // 2 = Agency(school) user, 1 = Org(police) user
                  uniqueId
                })
                  .then((message: string) => {
                    resolve(message);
                  })
                  .catch((err: string) => {
                    reject(err);
                  });
              }
            },
            error: (err: any) => {
              reject('Failed to register device.');
            }
          });
        } else {
          // If already registered, log in with device code and device key
          const password = user.password;
          const username = user.username.trim();
          const credentials = {
            credentials: {
              username,
              password,
              deviceCode,
              deviceKey
            },
            appType: this.applicationService.isOrgUser ? 2 : 1, // 2 = Agency(school) user, 1 = Org(police) user
            uniqueId: user.uniqueId
          };

          this.authenticate(credentials)
            .then((message: string) => {
              resolve(message);
            })
            .catch((err: string) => {
              reject(err);
            });
        }
      });
    });
  }

  attemptLogout() {
    return new Promise((resolve, reject) => {
      this.userService.getAuthToken().then((token: string) => {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        lastValueFrom<any>(this.http
          .post(`${environment.baseURL}/accounts/logout`, {}, { headers }))
          .then((response: any) => {
            const statusCode = response.responseSummary.statusCode;
            if (statusCode !== 0) {
              reject();
            }         
          })
          .then(() => {
            this.subscriptionService.unsubscribeFromNodes();
            resolve('Logout successful.');
          })
          .catch((err: any) => {
            // Expired token
            if (err.status === 401) {
              resolve('Session expired. Logging out.');
            }
            reject();
          });
      });
    }).catch((err: any) => {
      return;
    });
  }

  attemptForgotPassword(user: BaseUser, formIsValid: Boolean): Promise<string> {
    if (!formIsValid) {
      return;
    }

    return new Promise((resolve, reject) => {
      this.http.post(`${environment.baseURL}/accounts/forgot-password`, user).subscribe((response: any) => {
        const statusCode = response.responseSummary.statusCode;

        // Successful forgot password submission
        if (statusCode === 0) {
          resolve('Successfully submitted forgot password request');
        } else {
          reject(response.responseSummary.errorReason);
        }
      });
    });
  }

  authenticate(credentials: LoginCredentials): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.baseURL}/accounts/login`, credentials).subscribe(
        (response: any) => {
          const statusCode = response.responseSummary.statusCode;

          // Successful login
          if (statusCode === 0) {
            Promise.all([this.userService.getDeviceData(), this.notificationsService.getValidToken()]).then(
              async (values: any[]) => {
                const data: DeviceData = values[0];

                const firebaseToken: string = values[1];

                const deviceCode = data.deviceCode;
                const deviceKey = data.deviceKey;

                const sessionToken = response.SessionToken;
                const authJson = { deviceCode, deviceKey, sessionToken };

                const authToken = btoa(JSON.stringify(authJson));

                // Everything is starting in app component for notications - notification service is not needed.

                if (firebaseToken) {
                  this.notificationsService
                    .saveToken(authToken, firebaseToken, sessionToken)
                    .then((success: Boolean) => {
                      if (!success) {
                        this.uiAlertService.presentNotificationFailure();
                      }
                    })
                    .catch(() => {
                      this.uiAlertService.presentNotificationFailure();
                    });
                } else {
                  this.uiAlertService.presentNotificationFailure();
                }

                await Promise.all([
                  this.userService.setUserRaw(response, data),
                  this.subscriptionService.setPubSubsRaw(response.XMPPUsers),
                  this.userService.setAuthToken(authToken)
                ]);

                this.chatService.attemptConnection();

                resolve('Successful login');
              }
            );
          } else {
            // Failed login
            const errorReason = response.responseSummary.errorReason;
            if (errorReason === 'Invalid username or password.') {
              reject('Invalid login credentials.');
            }
            reject(errorReason);
          }
        },
        (failure: any) => {
          console.log(failure);
          reject('Failed to login. Please try again or contact your administrator.');
        }
      );
    });
  }

  validate() {
    return new Promise((resolve, reject) => {
      this.userService
        .getAuthToken()
        .then((token: string) => {
          const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
          let response = this.http.get(`${environment.baseURL}/accounts/validate`, { headers });
		  // The line below appears to cause an infinite loop
          lastValueFrom(response)
            .then((response: any) => {
              const statusCode = response.responseSummary.statusCode;
              if (statusCode === 0) {
                resolve('Valid token');
              } else {
                reject(response);
              }
            })
            .catch((err: any) => {
              reject(err);
            });
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
}
