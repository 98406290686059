import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TabsPage } from './tabs.page';
import { authGuard } from 'src/app/shared/guards/auth.guard';
import { DataResolverService } from 'src/app/shared/services/dataResolver.service';
import { ApplicationService } from 'src/app/shared/services/application.service';

const applicationService = new ApplicationService(null);
// Literal in place of applicationService.isOrgUser, can't use dynamically loaded class information in Angular templates
const applicationServiceIsOrgUser = false;

const routes: Routes = [
  {
    path: 'tabs',
    component: TabsPage,
    canActivate: [authGuard],
    children: [
      {
        path: 'categories',
        loadChildren: () => import('../categories/categories.module').then(m => m.CategoriesPageModule),
      },
      {
        path: 'new-reverse-alert',
        loadChildren: () => import('../new-reverse-alert/new-reverse-alert.module').then(m => m.NewReverseAlertPageModule),
      },
      {
        path: 'new-internal-notif',
        loadChildren: () => import('../new-internal-notif/new-internal-notif.module').then(m => m.NewInternalNotifPageModule)
      },
      {
        path: 'alerts',
        children: [
          {
            path: '',
            loadChildren: () => import('../alerts/alerts.module').then(m => m.AlertsPageModule)
          },
          {
            path: ':alertId',
            resolve: {
              alert: DataResolverService
            },
            loadChildren: () => import('../alert/alert.module').then(m => m.AlertPageModule)
          }
        ]
      },
      {
        path: 'settings',
        loadChildren: () => import('../settings/settings.module').then(m => m.SettingsPageModule)
      },
      {
        path: 'new-alert',
        loadChildren: () => import('../new-alert/new-alert.module').then(m => m.NewAlertPageModule)
      },
      {
        path: 'resources',
        loadChildren: () => import('../resources/resources.module').then(m => m.ResourcesPageModule)
      },
      {
        path: '',
        redirectTo: applicationServiceIsOrgUser ? 'categories' : 'new-reverse-alert',
        pathMatch: 'full',
      }
    ]
  }
];

// Org: use 'locations'
// Agency: use 'new-reverse-alert'

// Add the locations route for org users
if (applicationServiceIsOrgUser) {
  routes[0].children.unshift({
    path: 'locations',
    loadChildren: () => import('../locations/locations.module').then(m => m.LocationsPageModule)
  });
}

// Add the new reverse alert routes for agency users
if (!applicationServiceIsOrgUser) {
  routes[0].children.unshift({
    path: 'new-reverse-alert',
    loadChildren: () => import('../new-reverse-alert/new-reverse-alert.module').then(m => m.NewReverseAlertPageModule)
  });
}

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TabsPageRoutingModule { }
