import { Injectable, NgZone } from "@angular/core";
import { StorageService } from "./storage.service";
import { Capacitor } from "@capacitor/core";
import { UIAlertService } from "./uiAlert.service";
import { BehaviorSubject } from "rxjs";
import { ChatService } from "./chat.service";
import { AlertController, ToastController } from "@ionic/angular";
import { NotificationData } from "../models/notificationdata";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { FirebaseMessaging, GetTokenOptions, GetTokenResult, NotificationReceivedEvent } from "@capacitor-firebase/messaging";
import { initializeApp } from "firebase/app";
import { fi } from "date-fns/locale";
import { getMessaging, onBackgroundMessage } from "firebase/messaging/sw";

export const createNotificationService = (notificationService: NotificationsService) => {
  return () => notificationService.initPush();
}

@Injectable({
  providedIn: "root"
})
export class NotificationsService {
  private _redirect = new BehaviorSubject<any>(null);

  redirect$ = this._redirect.asObservable();

  constructor(
    private storage: StorageService,
    private uiAlertService: UIAlertService,
    private chatService: ChatService,
    private toastController: ToastController,
    private alertController: AlertController,
    private ngZone: NgZone,
    private http: HttpClient,
    private router: Router
  ) { }

  initPush() {
    this.registerPush();
  }

  private async registerPush() {
    try {
      if (Capacitor.isNativePlatform() === false) {
        let firebaseApp = initializeApp(environment.firebaseConfig);
      }

      let permStatus = await FirebaseMessaging.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await FirebaseMessaging.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        await this.uiAlertService.presentNotificationFailure();
        return;
      }

      let fcmToken: GetTokenResult = null;

      if (Capacitor.getPlatform() === 'web') {

        const handleNotification = (alertId, messageBody) => {
          console.log('Notification received: ', alertId, messageBody);
          const notification = new Notification("Inforce 911 Alert", {
            body: messageBody,
            data: alertId
          });
          this.presentToast(alertId, messageBody);
          notification.onclick = async (event) => {
            let alertId = event.currentTarget['data'];

            await this.ngZone.run(() => this.router.navigate(['tabs/alerts/' + alertId]))
              .then(null, () => this.presentError());

            notification.close();
          };
        };

        // Foreground event listener
        FirebaseMessaging.addListener('notificationReceived', (event: any) => {
          handleNotification(event.notification.data.alertId, event.notification.body);
        });
      }


      fcmToken = await this.GetAndStoreFCMToken(fcmToken);

      await this.addListeners();
    }
    catch (err: any) {
      console.log(err);
    }
  }

  private async GetAndStoreFCMToken(fcmToken: GetTokenResult) {
    if (Capacitor.getPlatform() === 'web') {
      const registration = await navigator.serviceWorker.ready;

      const tokenOptions: GetTokenOptions = {
        serviceWorkerRegistration: registration
      };
      fcmToken = await FirebaseMessaging.getToken(tokenOptions);
    }
    else {
      fcmToken = await FirebaseMessaging.getToken();
    }

    const savedToken = await this.storage.get('fcmToken');
    if (savedToken) {
      if (savedToken !== fcmToken.token) {
        await this.storage.set('fcmToken', fcmToken.token);
      }
    }
    else {
      await this.storage.set('fcmToken', fcmToken.token);
    }
    return fcmToken;
  }

  async getToken(): Promise<string> {
    const savedToken = await this.storage.get('fcmToken');
    return savedToken;
  }
  //fcmToken may not be present during user login. Check up to 30 seconds
  async getValidToken(): Promise<string> {
    const timeout = 30000; //15 seconds
    const startTime = Date.now();

    while (true) {
      const savedToken = await this.storage.get('fcmToken');
      if (savedToken !== null) {
        return savedToken;
      }
      // When timeout is reached return the value that is stored
      if (Date.now() - startTime >= timeout) {
        return savedToken;
      }
      //1 second delay after every try
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }

  saveToken(authToken: string, firebaseToken: string, sessionToken: string): Promise<Boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);
      const requestBody = {
        firebaseToken: firebaseToken,
        sessionToken: sessionToken
      };
      this.http
        .post(`${environment.baseURL}/notification/save`, requestBody, {
          headers
        })
        .subscribe({
          next: (response: any) => {
            if (response.responseSummary.statusCode === 0) {
              resolve(response.Success);
            } else {
              reject(false);
            }
          },
          error: () => {
            reject(false);
          }
        });
    });
  }

  async getDeliveredNotifications() {
    const notificationsList = await FirebaseMessaging.getDeliveredNotifications();
    return notificationsList;
  }

  async addListeners() {
    // Show us the notification payload if the app is open on our device
    await FirebaseMessaging.addListener('notificationReceived',
      async (event: NotificationReceivedEvent) => {
        let notification = event.notification.data as NotificationData;

        this.chatService.attemptConnection();

        if (Capacitor.getPlatform() === 'android') {
          this.presentToast(notification.alertId, notification.message);
        }
      }
    );

    // Method called when tapping on a notification
    await FirebaseMessaging.addListener('notificationActionPerformed',
      (event) => {
        let notification = event.notification.data as NotificationData;

        this.ngZone.run(() => this.router.navigate(['tabs/alerts/' + notification.alertId]))
          .then(null, () => this.presentError());
      }
    );

    if (Capacitor.isNativePlatform()) {
      await FirebaseMessaging.createChannel({
        id: "ReceiveAlert",
        name: "Receiving Alerts",
        description: "This notification plays when an alert or reverse alert is received",
        importance: 4,
        sound: 'notification_sound',
        lights: true,
        vibration: true
      }).then(() => console.log('Channel created'));
    }
  }

  async presentError() {
    const alert = await this.alertController.create({
      message: 'Failed to access alert. Please open it manually via the alert list.',
      header: 'Error',
      buttons: ['OK'],
      mode: Capacitor.getPlatform() === 'ios' ? 'ios' : 'md'
    });

    await alert.present();
  }

  async presentToast(alertId: string, message: string) {
    const duration = 5000;

    const toast = await this.toastController.create({
      message,
      position: 'top',
      buttons: [{
        text: 'Open',
        role: 'cancel'
      }],
      mode: 'md'
    });

    setTimeout(() => {
      toast.dismiss();
    }, duration);

    // Using closeButtonText and onDidDismiss() because the buttons property
    // was removed from the toastController at some point in Ionic's development
    toast.onDidDismiss().then(detail => {
      /**
       * This OverlayEventDetail object is NOT documented on Ionic Framework and could break at any time!
       * A cancel role means that the close button was tapped.
       */

      if (detail.role === 'cancel') {
        this.ngZone.run(() => this.router.navigate(['tabs/alerts/' + alertId]));
      }
    });

    toast.present();
    const audio = new Audio('alert.mp3');
    audio.play();
  }
}
